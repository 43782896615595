import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CBtnList,
  CanchorCard,
  CFixedImg,
  CCenterSlider,
  CRoomDesc,
  CDefinition,
  AssetImage,
  LOtherFloors,
  LWifi,
  ConceptMedia,
  LStayContact,
  LPlan,
} from '../../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        type="ja"
        size="large"
        data={{
          title: {
            main: 'スイートルーム／和室',
            sub: '65F 〜 66F',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/floor/suite_floor/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/floor/suite_floor/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'ご宿泊',
                path: '/stay/',
              },
              {
                label: '客室',
                path: '/stay/floor/',
              },
            ],
            current: {
              label: 'スイートルーム／和室',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mt60 u_mb30">
        <LWrap>
          <Link to="/stay/floor/club_lounge/">
            <CFixedImg
              exClass="u_mb60"
              width={640}
              img={{
                src: '/assets/images/stay/floor/img_loungebnr.png',
                alt: 'クラブラウンジ利用対象',
              }}
            />
          </Link>
          <div className="u_colorGold">
            <p className="c_smallHeading">クラブラウンジ利用対象</p>
            <p>
              スカイリゾートフロア「ザ・クラブ」の中でも、65～290平方メートルのゆとりある贅を尽くしたスイートルームです。
              <br />
              プレジデンシャルスイート、
              和室スイート、ロイヤルスイートのご予約はお電話で承ります。
            </p>
          </div>
          <CanchorCard
            col={2}
            data={[
              {
                img: {
                  src: '/assets/images/stay/floor/suite_floor/img_anchor.png',
                  alt: '',
                },
                link: {
                  href: '#comfortSuite',
                },
                title: {
                  main: 'COMFORT SUITE',
                  sub: 'コンフォートスイート',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/suite_floor/img_anchor02.png',
                  alt: '',
                },
                link: {
                  href: '#executiveSuite',
                },
                title: {
                  main: 'EXECUTIVE SUITE',
                  sub: 'エグゼクティブスイート',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/suite_floor/img_anchor03.png',
                  alt: '',
                },
                link: {
                  href: '#presidentalSuite',
                },
                title: {
                  main: 'PRESIDENTAL SUITE',
                  sub: 'プレジデンシャルスイート',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/suite_floor/img_anchor04.png',
                  alt: '',
                },
                link: {
                  href: '#japaneseSuite',
                },
                title: {
                  main: 'JAPANESE SUITE',
                  sub: '和室スイート',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/suite_floor/img_anchor05.png',
                  alt: '',
                },
                link: {
                  href: '#royalSuite',
                },
                title: {
                  main: 'ROYAL SUITE',
                  sub: 'ロイヤルスイート',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <div className="u_bgAccent">
        <LWrap exClass="u_mb90">
          <section className="" id="comfortSuite">
            <CSectTitle
              title={{
                ja: 'コンフォートスイート',
                en: 'COMFORT SUITE',
              }}
            />
            <p className="u_tac u_tal_sp u_mb30">
              独立したベッドルームとリビングルームが快適な客室です。
            </p>
            <CCenterSlider
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_comfort_suite.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_comfort_suite02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_comfort_suite03.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_comfort_suite.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_comfort_suite02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_comfort_suite03.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CRoomDesc
              exClass="u_mb60"
              left={
                <CDefinition
                  data={[
                    {
                      title: '広さ',
                      text: <>65m²</>,
                    },
                    {
                      title: '人数',
                      text: <>1～2名</>,
                    },
                    {
                      title: 'ベッドサイズ',
                      text: <>幅110cm×長さ210cm×2台</>,
                    },
                    {
                      title: '部屋数',
                      text: <>6室（禁煙）</>,
                    },
                  ]}
                />
              }
              right={
                <div className="">
                  <AssetImage
                    src="/assets/images/stay/floor/suite_floor/img_comfort_suite_floor.png"
                    alt=""
                  ></AssetImage>
                </div>
              }
            />
          </section>
          <section className="u_pt90" id="executiveSuite">
            <CSectTitle
              title={{
                ja: 'エグゼクティブスイート',
                en: (
                  <>
                    EXECUTIVE <br className="u_sp" />
                    SUITE
                  </>
                ),
              }}
            />
            <p className="u_tac u_tal_sp u_mb30">
              ゆったりとしたレイアウトの客室です。
              <br />
              エキストラベッド使用で3名様までご利用いただけます。
            </p>
            <CCenterSlider
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_executive_suite.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_executive_suite02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_executive_suite.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_executive_suite02.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CRoomDesc
              exClass="u_mb60"
              left={
                <CDefinition
                  data={[
                    {
                      title: '広さ',
                      text: <>88m²</>,
                    },
                    {
                      title: '人数',
                      text: <>1～3名</>,
                    },
                    {
                      title: 'ベッドサイズ',
                      text: <>幅110cm×長さ210cm×2台</>,
                    },
                    {
                      title: '部屋数',
                      text: <>4室（禁煙）</>,
                    },
                  ]}
                />
              }
              right={
                <div className="">
                  <AssetImage
                    src="/assets/images/stay/floor/suite_floor/img_executive_suite_floor.png"
                    alt=""
                  ></AssetImage>
                </div>
              }
            />
          </section>
          <section className="u_pt90" id="presidentalSuite">
            <CSectTitle
              title={{
                ja: 'プレジデンシャルスイート',
                en: (
                  <>
                    PRESIDENTAL <br className="u_sp" />
                    SUITE
                  </>
                ),
              }}
            />
            <p className="u_tac u_tal_sp u_mb30">
              ヨーロピアンテイストの洗練された室内で、優雅なひとときをお過ごしいただけます。
            </p>
            <CCenterSlider
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_presidental_suite.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_presidental_suite02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_presidental_suite.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_presidental_suite02.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CRoomDesc
              exClass="u_mb60"
              left={
                <CDefinition
                  data={[
                    {
                      title: '広さ',
                      text: <>130m²</>,
                    },
                    {
                      title: '人数',
                      text: <>1～2名</>,
                    },
                    {
                      title: 'ベッドサイズ',
                      text: <>幅120cm×長さ210cm×2台</>,
                    },
                    {
                      title: '部屋数',
                      text: <>1室（禁煙）</>,
                    },
                  ]}
                />
              }
              right={
                <div className="">
                  <AssetImage
                    src="/assets/images/stay/floor/suite_floor/img_presidental_suite_floor.png"
                    alt=""
                  ></AssetImage>
                </div>
              }
            />
          </section>

          <section className="u_pt90" id="japaneseSuite">
            <CSectTitle
              title={{
                ja: '和室スイート',
                en: (
                  <>
                    JAPANESE <br className="u_sp" />
                    SUITE
                  </>
                ),
              }}
            />
            <p className="u_tac u_tal_sp u_mb30">
              和の雰囲気を堪能できるスイートルームです。
            </p>
            <CCenterSlider
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_japanese_suite.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_japanese_suite02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_japanese_suite03.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_japanese_suite.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_japanese_suite02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_japanese_suite03.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CRoomDesc
              exClass="u_mb60"
              left={
                <CDefinition
                  data={[
                    {
                      title: '広さ',
                      text: <>130m²</>,
                    },
                    {
                      title: '人数',
                      text: <>1～4名</>,
                    },
                    {
                      title: 'ベッドサイズ',
                      text: <>幅110cm×長さ200cm 布団2組</>,
                    },
                    {
                      title: '部屋数',
                      text: <>1室（禁煙）</>,
                    },
                  ]}
                />
              }
              right={
                <div className="">
                  <AssetImage
                    src="/assets/images/stay/floor/suite_floor/img_japanese_suite_floor.png"
                    alt=""
                  ></AssetImage>
                </div>
              }
            />
          </section>
          <section className="u_pt90" id="royalSuite">
            <CSectTitle
              title={{
                ja: 'ロイヤルスイート',
                en: 'ROYAL SUITE',
              }}
            />
            <p className="u_tac u_tal_sp u_mb30">
              横浜の海や街を一望できる贅沢なロケーション。
              <br />
              1日1組限定、貸切りでウエディングも執り行います。
            </p>
            <CCenterSlider
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_loyal_suite.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_loyal_suite02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_loyal_suite03.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_loyal_suite.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_loyal_suite02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/suite_floor/img_loyal_suite03.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CRoomDesc
              exClass="u_mb60"
              left={
                <CDefinition
                  data={[
                    {
                      title: '広さ',
                      text: <>290m²</>,
                    },
                    {
                      title: '人数',
                      text: <>1〜2名</>,
                    },
                    {
                      title: 'ベッドサイズ',
                      text: <>幅154cm×長さ210cm×2台</>,
                    },
                    {
                      title: '部屋数',
                      text: <>1室（禁煙）</>,
                    },
                  ]}
                />
              }
              right={
                <div className="">
                  <AssetImage
                    src="/assets/images/stay/floor/suite_floor/img_loyal_suite_floor.png"
                    alt=""
                  ></AssetImage>
                </div>
              }
            />
          </section>
        </LWrap>
      </div>
      <section className="u_bgWhite l_sect">
        <LWrap>
          <ul
            className="c_noteList u_bgAccent u_mb60"
            style={{ padding: '25px' }}
          >
            <li>
              チェックイン・チェックアウトは、66F専用レセプションにて承ります。
            </li>
            <li>クラブラウンジ営業時間外は1Fフロントをご利用ください。</li>
          </ul>
          <CSectTitle
            title={{
              ja: <>スペック</>,
              en: <>SPEC</>,
            }}
          />
          <h4 className="c_headingLv4 u_mb15 u_tal">客室設備・備品</h4>
          <p className="u_mb30">
            ベッド （シモンズ社製マットレス） ／
            液晶テレビ（地上デジタル放送　BS放送　WOWOW） ／
            タッチパネル型コントロールパネル ／ 加湿空気清浄機 ／ 電話 ／
            室内金庫 ／ 冷蔵庫 ／ コーヒーマシン ／ 電気ポット ／ 日本茶 ／
            ミネラルウォーター ／ タオル・バスタオル ／ スリッパ ／
            ヘアドライヤー ／ バスローブ ／ パジャマ
          </p>
          <h4 className="c_headingLv4 u_mb15 u_tal">アメニティグッズ</h4>
          <p className="u_mb30">
            バスアメニティ(シャンプー・コンディショナー・ボディウォッシュ) ／
            歯ブラシ・歯磨き粉 ／ ひげそり・シェイビングフォーム ／
            綿棒・コットンセット・ヘアブラシ
          </p>
          <h4 className="c_headingLv4 u_mb15 u_tal">通信環境</h4>
          <p className="u_mb30">Wi-Fi 接続無料 ／ 電話回線／ TV</p>
          <h4 className="c_headingLv4 u_mb15 u_tal">貸出備品</h4>
          <p className="u_mb30">
            ブルーレイ・CDプレイヤー ／ アイロン・アイロン台 ／ ズボンプレッサー
            ／ 電気スタンド ／ 花瓶 ／ ベビーベッド ／ ベッドガード
            <ul className="c_noteList u_mt10">
              <li>台数に限りがございますので、ご予約をおすすめいたします。</li>
              <li>
                上記以外にも貸出備品を取り揃えております。詳しくはお問合せください。
              </li>
            </ul>
          </p>
          <h4 className="c_headingLv4 u_mb15 u_tal">サービス</h4>
          <p className="u_mb30">
            <Link to="/stay/room_service/">ルームサービス</Link>
            <br />
            <Link to="/stay/relaxation/">インルームリラクゼーション</Link>
            <br />
            ランドリー
            <br />
            無料インターネット（Wi-Fi）
            <br />
            <Link to="/about/access/car/">駐車場</Link>
            <br />
            <Link to="/stay/floor/club_lounge/">クラブラウンジのご利用</Link>
          </p>
          <p className="u_fwb u_tal">
            <Link to="/facilities/landmark_spa/">
              ランドマークスパのご利用無料
            </Link>
          </p>
          <CBtnList
            data={[
              {
                label: '客室一覧',
                link: {
                  href: '/stay/floor/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <LPlan />
      <LOtherFloors />
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LStayContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
